@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alex+Brush&display=swap);
:root {
  --main-bg: #aaaaaa;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
}

body {
  margin: 0;
  line-height: 1.5rem;
  font-family: "Open Sans", sans-serif;
  color: black;
  background-color: #fff;
}
html {
  scroll-behavior: smooth;
}
h1 {
  font-size: 1.5rem;
}
p {
  font-size: 1.1rem;
}
span {
  font-size: 1.2rem;
}
a {
  text-decoration: none;
  color: black;
}
.section-title {
  font-family: "Alex Brush", cursive;
  font-size: calc(3rem + 2vw);
  margin: 50px;
}

/* APP */

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #cecaaa; */
  background-color: #aaaaaa;
  background-color: var(--main-bg);
}
.Main {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  min-width: 200px;
  box-shadow: 0px 10px 10px black;
  background-color: white;
}

/* APP END */

@media (max-width: 1000px) {
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.1rem;
  }
  span {
    font-size: 1.2rem;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .section-title {
    font-size: calc(3rem + 2vw);
    margin: 30px;
  }
}

/* COLOR VARS */

:root {
  --section-bg:#fff;
  --alt-section-bg:#f0f0f0;
  --lighter-purple: #bfb6cd;
  --light-purple: #9aa4b4;
  --dark-purple: #5a4153;
}
/* FONTS */


/* BANNER */

.banner-main {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
.banner-img {
  width: 100%;
  z-index: 1;
  background-color: rgb(255, 255, 255);

}
/* BANNER END */

/* NAVBAR */

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  background-color: #bfb6cd;
  background-color: var(--lighter-purple);
  box-shadow: 0px 1px 5px black;
  z-index: 100;
}
.nav-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.nav-link {
  padding: 15px;
  cursor: pointer;
  font-size: medium;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.nav-link:active {
  border-bottom: 2px solid black ;
  -webkit-transition: all 3s;
  transition: all 3s;
}

/* NAVABR END */

/* FIRST */

.greeting-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  background-color: #fff;
  background-color: var(--section-bg);
}

.one {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.one img {
  flex: 1 1;
  width: 60%;
  margin: 10px;
  min-width: 200px;
  max-width: 500px;
  border-radius: 5px;
}
.two {
  flex: 1.1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.two-content {
  padding: 20px;
  width: 80%;
  border-radius: 10px;
  background-color: #9aa4b4;
}
.two h1 {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}
.two p {
  padding: 10px;
}
/* @media (orientation: landscape) {
  .first-section {
    flex-direction: row;
  }
} */

/* FIRST END */

/* THIRD  */

.gallery-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f0f0f0;
  background-color: var(--alt-section-bg);
}
.gallery-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1400px;
}
.gallery-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-body img {
  width: 30%;
  padding: 10px;
  margin: 10px;
}
.gal-left, .gal-right {
  width: 4%;
}
.gallery-ender {
  width: 50%;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
}

/* THIRD END */

/* MEETUS */

.meetus-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.meetus-section img{
  width: 250px;
  /* margin-top: 20px; */
}
.meetus-team{
  display: flex;
  width: 80%;
}
.meetus-one p, .meetus-two p{
  width: 70%;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
}
.meetus-one, .meetus-two{
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* MEETUS END */

/* IN TOUCH */

.intouch-section{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #f0f0f0;
  background-color: var(--alt-section-bg);
}
.intouch-body{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
}
.follow-float{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.follow-items {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  font-weight: bold;
}
.follow-button a{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.follow-button span {
  margin-left: 20px;
  text-transform: none;
}
.follow-button img {width: 40px;}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: up;
  font-weight: bold;
}
.contact-float{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.contact-items {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: bold;
}
.contact-info p {
  margin: 5px;
}

/* IN TOUCH END */

/* FOOTER  */

.footer-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #bfb6cd;
  background-color: var(--lighter-purple);
  padding: 35px 0px 35px 0px;
}
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}
.footer-title {
  padding: 10px;
}
.footer-paragraph {
  font-size: 1.3rem;
  padding: 10px;
}
.footer-body {
  width: 50%;
  margin: 10px;
  padding: 10px;
  border-radius: 7px;
  background-color: #5a4153;
  background-color: var(--dark-purple);
}
.footer-body input {
  width: 60%;
  padding: 12px;
  margin-right: 10px;
  border: 2px solid transparent;
  outline: none;
}
.footer-body button {
  padding: 10px;
  border: none;
  cursor: pointer;
  background-color: white;
}
/* FOOTER END */


/* MEDIA QUERIES */
@media (min-width:1000px){
  .greeting-section,
  .gallery-section,
  .meetus-section,
  .intouch-section{
    min-height: 700px;
  } 
}
@media (max-width: 1000px) {
  .greeting-section,
  .gallery-section,
  .meetus-section,
  .intouch-section{
    min-height: 300px;
  } 
  .banner-main {
    max-height: 30vh;
  }
  .greeting-section {
    flex-direction: column;
    width: 90%;
  }
  .one img {
    max-width: 95%;
  }
  .two-content {
    padding: 10px;
    width: 100%;
  }
  .call-bar {
    flex-direction: column;
  }
  .gallery-ender {
    width: 90%;
  }
  .gallery-body {
    padding: 0px;
    background-color: transparent;
  }
  .gallery-body img {
    width: 30%;
    padding: 0px;
    margin: 5px;
  }
  .meetus-section img{
    width: 150px;
  }
  .meetus-one p, .meetus-two p{
    width: 100%;
  }
  .meetus-team{
    width: 90%;
    flex-direction: column;
  }
  .intouch-body{flex-direction: column;}
  .follow-items{flex-direction: row;}
  .follow-button span{display:none;}
  .follow-float{order:1;width: 100%;}
  .follow-items{justify-content: space-evenly;width: 100%;}
  .contact-info{margin-bottom: 10px;}
  .footer-body {
    width: 100%;
  }
  
}

/* MEDIA QUERIES END */

